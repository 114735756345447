import { NavLink } from "react-router-dom";

const OfferBanner = () => {
    return (
        <div style={{ boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px' }} className="flex largeTablet:flex-row smallPhone:flex-col-reverse largeTablet:w-full desktop:h-[19rem] largeTablet:h-[15rem] justify-between items-center bg-white overflow-hidden rounded-lg">
            <div className="largeTablet:px-0 smallTablet:px-10 largePhone:px-8 smallPhone:px-6 largeTablet:pl-12 largeTablet:pb-0 smallTablet:pb-10 largePhone:pb-8 smallPhone:pb-6">
                <p style={{ lineHeight: "1.4" }} className="desktop:text-[1.4rem] largeTablet:text-[1.3rem] smallTablet:text-[1.4rem] largePhone:text-[1.25rem] smallPhone:text-[1rem] text-font_two font-medium desktop:mb-10 smallTablet:mb-7 largePhone:mb-6 smallPhone:mb-5">Looking for affordable <b className="text-primary desktop:text-[1.6rem] largeTablet:text-[1.35rem] smallTablet:text-[1.45rem] largePhone:text-[1.3rem] smallPhone:text-[1.15rem]">One-on-One</b> virtual classes? Book a completely <b className="text-primary desktop:text-[1.6rem] largeTablet:text-[1.35rem] smallTablet:text-[1.45rem] largePhone:text-[1.3rem] smallPhone:text-[1.15rem]">Free Trial</b> today & experience the benefits firsthand.</p>
                <NavLink to="https://wa.me/+917051144137" target="_blank" className="text-white bg-primary largePhone:text-lg smallPhone:text-base font-medium border-2 border-primary largePhone:py-2 smallPhone:py-1 smallTablet:px-12 largePhone:px-8 smallPhone:px-6 rounded transition ease-in-out delay-150 hover:bg-[#19a095]">Book Now</NavLink>
            </div>
            <img className="desktop:w-[575px] largeTablet:w-[455px] largeTablet:-mr-10 largeTablet:-mt-0 smallTablet:-mt-14 largePhone:-mt-10 smallPhone:-mt-8" src="/images/posterImg.svg" alt="Virtual One-on-One Classes" />
        </div>
    )
}

export default OfferBanner;