import Header from '../../components/Header/Header';
import Brand from '../../components/Header/Brand/Brand';
import { NavLink } from 'react-router-dom';
import Quote from '../../components/shared/Quote/Quote';

const About = () => {
    return (
        <>
            <section>
                <Header>
                    <Brand />
                </Header>
            </section>
            <div className='wideScreen:px-36 desktop:px-28 largeTablet:px-16 largePhone:px-10 smallPhone:px-10 my-20 overflow-hidden'>
                <div className='flex mediumTablet:flex-row smallPhone:flex-col items-center largeTablet:space-x-10 mediumTablet:space-x-5 mediumTablet:space-y-0 smallPhone:space-y-5 mb-10'>
                    <img data-aos="fade-right" className='desktop:w-[580px] largeTablet:w-[450px] smallTablet:w-[380px] largePhone:w-[320px] smallPhone:w-[280px] mediumTablet:rounded-3xl smallPhone:rounded-full' src="images/admin2.jpg" alt="instructor-about" />
                    <div data-aos="fade-left" className="flex flex-col mediumTablet:items-start smallPhone:items-center">
                        <h2 className='largeTablet:text-4xl largePhone:text-3xl smallPhone:text-2xl font-semibold text-font_one mb-5'>About Us</h2>
                        <h4 className='largeTablet:text-2xl largePhone:text-xl smallPhone:text-lg text-font_two font-medium mb-2'>Hello! Welcome to TheChosenField! Really happy to see you here.</h4>
                        <p className='largeTablet:text-lg largePhone:text-base smallPhone:text-sm text-font_two font-normal text-justify'>With the vision of imparting quality education through sustainable manner, an idea turned into an Academy, me and out core members are working day and night in developing courses that just are exceptional in this modern day education. We are committed in delivering Courses to Live 1:1 Counselling sessions in Personal and Academic Issues, As a team we are here to improve you and your career as we do. For any additional questions, feel free to contact us at <NavLink className="text-primary font-medium" to="/contact">www.thechosenfield.com/contact</NavLink></p>
                    </div>
                </div>
                <div className='flex mediumTablet:flex-row smallPhone:flex-col-reverse items-center largeTablet:space-x-10 mediumTablet:space-x-5'>
                    <div data-aos="fade-right" className="flex flex-col mediumTablet:items-start smallPhone:items-center mediumTablet:mt-0 largePhone:mt-5 smallPhone:mt-5">
                        <h2 className='largeTablet:text-4xl largePhone:text-3xl smallPhone:text-2xl font-semibold text-font_one mb-5'>Our Story</h2>
                        <h4 className='largeTablet:text-2xl largePhone:text-xl smallPhone:text-lg text-font_two font-medium mb-2'>At TheChosenField, we aim to provide new students with proper mentorship and education for their learning and growth.</h4>
                        <p className='largeTablet:text-lg largePhone:text-base smallPhone:text-sm text-font_two font-normal text-justify'>Greetings! I am Sajad Ahmed Qureshi
                            Specialise in Biology with a Minor in Economics and Business Management, and Environmental Science, Holding an enriched seven years of teaching experience in both online and face-to-face, I literally started tutoring when I was only a High School Kid, I have committed nearly a decade of my life in tutoring students for their APs, SATs, British Curriculum like GCSE to A Levels to IB to Common Core and Texas Curriculum as well as NSW and HSC. I have taught more toughest curriculums like SQA and Subjects like Molecular Biology with zeal and compassion, I dedicate my expertise to a wide variety of subjects from Combined Science (Physics Chemistry, and Biology) of Lower Levels to an Extensive approach in Higher Level Biology, AP Environmental Science, AP Biology, High School Economics, Biology Honours, HSC, AP Macroeconomics, AP Microeconomics and many more to count.</p>
                    </div>
                    <img data-aos="fade-left" className='desktop:w-[580px] largeTablet:w-[450px] smallTablet:w-[380px] largePhone:w-[320px] smallPhone:w-[280px] mediumTablet:rounded-3xl smallPhone:rounded-full' src="images/admin1.jpg" alt="instructor-story" />
                </div>

                <Quote quote="
If kids can be super engaged in video games, there's a way for them to be super engaged in education as well." name="Elon Musk" />
            </div>
        </>
    );
}

export default About;