import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"
import { useState } from "react";
import OfferBanner from "../OfferBanner/OfferBanner";
import { useEffect } from "react";

const LoadModal = () => {
    const [open, openchange] = useState(false);
    const closepopup = () => {
        openchange(false);
    }

    useEffect(() => {
        setTimeout(() => {
            openchange(true);
        }, 5000);
    }, []);
    return (
        <div style={{ textAlign: 'center' }}>
            <Dialog open={open} onClose={closepopup} fullWidth maxWidth="md">
                <DialogContent style={{ position: "relative", padding: "0px", overflow: "hidden" }}>
                    <IconButton onClick={closepopup} className="right-0 top-0" style={{ position: 'absolute' }}><CloseIcon className="text-primary z-10"></CloseIcon></IconButton>
                    <OfferBanner />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default LoadModal;